<div class="card rounded shadow-sm">
  <form [formGroup]="form">
    <div class="p-3">
      <div class="form-row">
        <div class="form-check col-md-2">
          <label>Status</label><br>
          <label class="control control-checkbox">
            Ativo
            <input type="checkbox" formControlName="isActive" />
            <div class="control_indicator"></div>
          </label>
        </div>


        <div class="form-group col-md-5">
          <label>Email</label>
            <div *ngIf="acesso.endUserId;else other_content">
            <p>{{ acesso.userId === authService.decodedToken?.nameid  ? acesso.endUserAcesso.email : acesso.userAcesso.email  }}

            </p>
            </div>
          <ng-template #other_content>
            <div>
              <input type="text" class="form-control" formControlName="email" placeholder="" ngBlur="GetUser()">
            </div>
          </ng-template>
        </div>

        <div *ngIf="saveState=='post';else other_content1"  class="form-group col-md-5">
          <label>Localizar usuário</label><br>
          <button type="button" class="btn btn-warning btn-me" tooltip="Localizar usuário" (click)="openModal($event, template)">
            <i class="fa fa-spinner "></i>
            </button>
        </div>
        <ng-template #other_content1>
          <div class="form-group col-md-5">
            <label>Usuário</label><br>
            {{ acesso.userId === authService.userId()  ? acesso.endUserAcesso.fullName : acesso.userAcesso.fullName  }}

          </div>
        </ng-template>



      </div>
      <div class="form-row">
        <div *ngIf="!acesso.userId || acesso.userId === authService.userId();else other_content2" class="form-check col-md-2">
          <label>Administrador</label><br>
          <label class="control control-checkbox">
            Admin
            <input type="checkbox" formControlName="isAdmin" [disabled]="disabled" />
            <div class="control_indicator"></div>
          </label>
        </div>
        <ng-template #other_content2>
          <div class="form-group col-md-2">
            <label>Administrador</label><br>
            {{acesso.isAdmin ? "Sim" : "Não" }}
          </div>
        </ng-template>
        <!--
        <div *ngIf="!acesso.userId || acesso.userId === authService.decodedToken?.nameid;else other_content3" class="form-check col-md-2">
          <label>Grupos</label><br>
          <label class="control control-checkbox">
            Todos
            <input type="checkbox" formControlName="IsAllGroups" [disabled]="disabled" />
            <div class="control_indicator"></div>
          </label>
        </div>
        <ng-template #other_content3>
          <div class="form-group col-md-2">
            <label>Todos Grupos</label><br>
            {{acesso.IsAllGroups ? "Sim" : "Não" }}
          </div>
        </ng-template>

        <div *ngIf="!acesso.userId || acesso.userId === authService.decodedToken?.nameid;else other_content4" class="form-group col-md-5">
          <label>Grupo</label>
          <select multiple class="form-control" formControlName="groupId">

            <option *ngFor="let group of groups" value= {{group.id}}>
              {{group.groupName}}</option>
          </select>
        </div>
        <ng-template #other_content4>
          <div class="form-group col-md-5">
            <label>Grupo</label>

             <td>{{acesso.group?.groupName ? acesso.group?.groupName : "Todos" }}</td>

          </div>
        </ng-template>
        -->

      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex">
        <button class="btn btn-outline-secondary mr-auto border" (click)="resetForm()">
          Cancelar Alteração
        </button>
        <button class="btn btn-success" [disabled]="!form.valid" (click)="saveAcesso()">
          Salvar Acesso
        </button>
      </div>
    </div>
  </form>

</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>O usuário deve ter se cadastrado previamente na plataforma, confirma se usário já cadastrado?</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Sim</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >Não</button>
  </div>
</ng-template>
