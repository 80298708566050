import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/services/auth.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Forgot } from '@app/models/Forgot';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  form!: FormGroup;
  modalRef!: BsModalRef;
  public showSuccess = false as boolean;
  public showError = false as boolean;
  public errorMessage = '' as string;
  public successMessage = '' as string;

  titulo = 'Forgot';
  model: any = {};

  constructor(
              private authService: AuthService,
              public router: Router,
              public browserModule: BrowserModule,
              public commonModule: CommonModule,
              private toastr: ToastrService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.form = new FormGroup({
    email: new FormControl('', [Validators.required])
    });
  }

  public validateControl = (controlName: string) => {
    return this.form.controls[controlName].invalid && this.form.controls[controlName].touched;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  public forgotPassword = (formValue: any) => {
    this.showError = this.showSuccess = false;

    const forgotPass = { ...formValue };
    const forgot: Forgot = {
      email: forgotPass.email,
      clientURI: 'http://localhost:4200/authentication/resetpassword'
    };

    this.authService.forgotPassword('forgot', forgot)
    .subscribe(_ => {
      this.showSuccess = true;
      this.successMessage = 'O link foi enviado, por favor verifique o seu email para resetar sua senha.';
    },
    error => {
      this.showError = true;
      this.errorMessage = error;
    });
  }


}
