import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Group } from '@app/models/Group';
import { User } from '@app/models/User';
import { GroupService } from '@app/services/group.service';
import { AcessoService } from '@app/services/acesso.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit {
  modalRef!: BsModalRef;
  group = {} as Group;
  users = {} as User[];
  saveState = 'post' as string;
  form!: FormGroup;

  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }
  constructor(private fb: FormBuilder,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private groupService: GroupService,
              private acessoService: AcessoService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private modalService: BsModalService,

              ) { }

  ngOnInit(): void {
    this.loadGroup();
    this.getUsers();
    this.validation();
  }


  public loadGroup(): void {
    const groupIdParam = this.activatedRouter.snapshot.paramMap.get('id');
    if (groupIdParam != null) {
      this.spinner.show();
      this.saveState = 'put';
      this.groupService.getGroupById(groupIdParam).subscribe(
        (group: Group) => {
          this.group = {...group};
          this.form.patchValue(this.group);
        },
        (error: any) => {
          console.error(error);
        },
        () => {
          this.spinner.hide();
        },
      );
    }

  }

  public validation(): void{
    this.form = this.fb.group({
      id: [],
      isActive: [],
      groupName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      groupDescription: ['', [ Validators.maxLength(250)]],
      groupPassword: ['', [ Validators.maxLength(10)]],
      userId: []
    });
  }

  public resetForm(): void {
    this.loadGroup();
    this.validation();
  }

  public resetPassword(): void {
    this.loadGroup();
    this.validation();
  }

  public newPassword(): void {
    let password = '';
    password = this.generateRandomPassword();
    // console.log(password);
    this.form.controls.groupPassword.setValue(password);

  }

  public generateRandomPassword(): string {
    let password = '';
    const possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 6; i++) {
        password += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }
    return password;
}

openModal(event: any, template: TemplateRef<any>): void {
  event.stopPropagation();
  this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
}

confirm(): void {
  // this.message = 'Confirmed!';

  this.modalRef.hide();
  this.spinner.show();
  this.newPassword();
  this.toastr.success('Senha alterada com successo, confirme no botão salvar.', 'Atualizado!');
  this.spinner.hide();

}

decline(): void {
  // this.message = 'Declined!';
  this.modalRef.hide();
}

public getUsers(): void {
  // tslint:disable-next-line: deprecation
  this.acessoService.getUsers().subscribe({
      next: (users: User[]) => {
        this.users = users;
      },
      error:  (error: any) => {
        this.spinner.hide();
        this.toastr.error('Error to load Users', 'Error!');
      },
    complete: () => this.spinner.hide(),
    });
}


  public saveGroup(): void {
    this.spinner.show();
    if (this.form.valid) {
      this.group = {...this.form.value};


      this.group = (this.saveState === 'post')
                ? {...this.form.value}
                : {id: this.group.id, ...this.form.value};
      if (this.saveState === 'post') {

        // this.groupService.post(this.group).subscribe(
        this.groupService.post(this.group).subscribe(
            (groupReturn: Group) => {
              this.toastr.success('Grupo salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`groups/detail/${groupReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(error);
              this.toastr.error('Error ao salvar grupo....', 'Erro');
            },
            () => this.spinner.hide()
          );
      } else {
        this.groupService.put(this.group).subscribe(
            (groupReturn: Group) => {
              this.toastr.success('Grupo salvo com Sucesso!', 'Sucesso');
              this.router.navigate([`groups/detail/${groupReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error('Error ao salvar grupo', 'Erro');
            },
            () => this.spinner.hide()
          );

      }
    }

  }
}
