<div class="card rounded shadow-sm">
  <form [formGroup]="form">
    <div class="p-3">
      <div class="form-row">
        <div class="form-check col-md-2">
          <label>Status</label><br>
          <label class="control control-checkbox">
            Ativo
            <input type="checkbox" formControlName="isActive" />
            <div class="control_indicator"></div>
          </label>
        </div>
        <div class="form-group col-md-5">
          <label>Grupo</label>
          <input type="text" class="form-control" formControlName="groupName" placeholder="">
        </div>
        <div class="form-group col-md-5">
          <label>Descrição</label>
          <input type="text" class="form-control" formControlName="groupDescription" placeholder="">
        </div>
      </div>


    <div class="form-row">
      <div class="form-check col-md-2">
      </div>
      <div class="form-group col-md-5">
        <label>Proprietário</label>
        <select class="form-control" formControlName="userId">
          <option *ngFor="let user of users" value= {{user.id}}>
            {{user.userName}}</option>
        </select>
      </div>
    </div>

      <div class="form-row">
        <div class="form-check col-md-2">


        </div>
        <div class="form-group col-md-5">
          <label>Senha</label>
          <input type="text" class="form-control" formControlName="groupPassword" placeholder="" readonly>
        </div>
        <div class="form-group col-md-5">
          <label>Gerar nova senha</label><br>
          <button type="button" class="btn btn-warning btn-sm" tooltip="Gerar nova senha" (click)="openModal($event, template)">
            <i class="fa fa-plus-circle "></i>
            </button>
        </div>
      </div>

    </div>




    <div class="card-footer">
      <div class="d-flex">
        <button class="btn btn-outline-secondary mr-auto border" (click)="resetForm()">
          Cancelar Alteração
        </button>
        <button class="btn btn-success" [disabled]="!form.valid" (click)="saveGroup()">
          Salvar Grupo
        </button>
      </div>
    </div>
  </form>

</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>Atualizar a senha pode parar os monitoramentos do grupo {{this.form.get("groupName")?.value}}, será preciso reconfigurar monitoramentos, confirma?</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Sim</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >Não</button>
  </div>
</ng-template>
