import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MonitorsComponent } from './components/monitors/monitors.component';
import { ItensComponent } from './components/itens/itens.component';
import { GroupsComponent } from './components/groups/groups.component';
import { AcessosComponent } from './components/acessos/acessos.component';
import { PerfilComponent } from './components/user/perfil/perfil.component';
import { PasswordComponent } from './components/user/password/password.component';
import { ConfirmationComponent } from './components/user/confirmation/confirmation.component';
import { ForgotComponent } from './components/user/forgot/forgot.component';
import { ResetComponent } from './components/user/reset/reset.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MonitorListComponent } from './components/monitors/monitor-list/monitor-list.component';
import { MonitorDetailComponent } from './components/monitors/monitor-detail/monitor-detail.component';

import { GroupListComponent } from './components/groups/group-list/group-list.component';
import { GroupDetailComponent } from './components/groups/group-detail/group-detail.component';

import { AcessoListComponent } from './components/acessos/acesso-list/acesso-list.component';
import { AcessoDetailComponent } from './components/acessos/acesso-detail/acesso-detail.component';


import { ItemListComponent } from './components/itens/item-list/item-list.component';
import { ItemDetailComponent } from './components/itens/item-detail/item-detail.component';

import { NavComponent } from './shared/nav/nav.component';
import { TitleComponent } from './shared/title/title.component';
import { MonitorService } from './services/monitor.service';
import { GroupService } from './services/group.service';
import { AcessoService } from './services/acesso.service';
import { ItemService } from './services/item.service';
import { DateTimeFormatPipe } from './helpers/DateTimeFormat.pipe';


import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UserComponent } from './components/user/user.component';
import { LoginComponent } from './components/user/login/login.component';
import { RegistrationComponent } from './components/user/registration/registration.component';
import { AuthInterceptor } from './auth/auth.interceptor';

// import {MatCheckboxModule} from '@angular/material/checkbox';

import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MonitorsComponent,
    MonitorListComponent,
    MonitorDetailComponent,
    ItensComponent,
    ItemListComponent,
    ItemDetailComponent,
    GroupsComponent,
    GroupListComponent,
    GroupDetailComponent,
    AcessosComponent,

    AcessoListComponent,
    AcessoDetailComponent,

    PerfilComponent,
    PasswordComponent,
    DashboardComponent,
    NavComponent,
    TitleComponent,
    DateTimeFormatPipe,
    UserComponent,
    LoginComponent,
    RegistrationComponent,
    ConfirmationComponent,
    ForgotComponent,
    ResetComponent
    ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
//    MatCheckboxModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing'
    }),
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule
 ],
  providers: [MonitorService,
              GroupService,
              ItemService,
              AcessoService,
              {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true
              }
   ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
