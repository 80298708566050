<div class="card">
  <div class="card-body">
      <h1 class="card-title">Atualizar Senha</h1>

      <div *ngIf="showError" class="alert alert-danger" role="alert">
          <span [innerHTML]="errorMessage"></span>
      </div>

      <div *ngIf="showSuccess" class="alert alert-success" role="alert">
          Sua senha foi atualizada. Para acessar <a [routerLink]="['/user/login']"> click no link. </a>
      </div>

      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="resetPassword(form.value)">
          <div class="form-group row">
            <div class="col-md-5">
              <label for="password">Senha:</label>
              <div class="col-md-5">
                <input type="password" class="form-control"
                [ngClass]="{'is-invalid': f.password.errors && f.password.touched}" formControlName="password" placeholder="Insira a senha">
                <div *ngIf="f.password.errors?.required" class="invalid-feedback">
                  Senha é obrigatória.
                </div>
                <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">
                  Senha deve ter 6 caracteres.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-5">
              <label for="confirmPassword">Confirmar Senha:</label>
              <input type="password" class="form-control"
              [ngClass]="{'is-invalid': f.confirmPassword.errors && f.confirmPassword.touched}" formControlName="confirmPassword" placeholder="Confirme a Senha">
              <div *ngIf="f.confirmPassword.errors?.required" class="invalid-feedback">
                Confirmação de Senha é obrigatória.
              </div>
              <div *ngIf="f.confirmPassword.errors?.mustMatch" class="invalid-feedback">
                Confirmação de Senha não confere.
              </div>
            </div>
          </div>
          <br>
          <div class="form-group row">
              <div class="col-md-1">
                  <button type="submit" class="btn btn-info" [disabled]="!form.valid">Submit</button>
              </div>
          </div>
      </form>
  </div>
</div>
