<div class="row mt-4">

	<div class="col-md-6 d-none d-md-block" style="height: 450px;">
		<div class="text-center col-md-12 my-auto side-heder">
			<h1>Cadastro de Usuário</h1>
			<img src="../../../../assets/registerImage.png" class="my-4" alt="" width="200px">
		</div>
	</div>

	<div class="col-md-6">

		<!--FORM VAI AQUI-->
    <form [formGroup]="form" class="p-1">
      <div class="pb-2 d-block d-md-none">
        <h3 class="border-bottom">Cadastro de Usuário</h3>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Primeiro Nome</label>
          <input type="text" class="form-control"
          [ngClass]="{'is-invalid': f.firstName.errors && f.firstName.touched}" formControlName="firstName" placeholder="Insira o Primeiro nome">
          <div *ngIf="f.firstName.errors?.required" class="invalid-feedback">
            Primeiro nome é obrigatório.
          </div>

        </div>
        <div class="form-group col-sm-6">
          <label>Último nome</label>
          <input type="text" class="form-control"
          [ngClass]="{'is-invalid': f.lastName.errors && f.lastName.touched}" formControlName="lastName" placeholder="Insira o Último nome">
          <div *ngIf="f.lastName.errors?.required" class="invalid-feedback">
            Último nome é obrigatório.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="">Email:</label>
        <input class="form-control" type="text"
        [ngClass]="{'is-invalid': f.email.errors && f.email.touched}" formControlName="email" placeholder="Insira e-mail">
        <div *ngIf="f.email.errors?.required && f.email.touched" class="invalid-feedback">
          E-mail é obrigatório.
        </div>
        <div *ngIf="f.email.errors?.email && f.email.touched" class="invalid-feedback">
          E-mail não é válido.
        </div>

      </div>
      <div class="form-group">
        <label for="">Usuario:</label>
        <input type="text" class="form-control"
        [ngClass]="{'is-invalid': f.userName.errors && f.userName.touched}" formControlName="userName" placeholder="Insira o nome de usuário">
        <div *ngIf="f.userName.errors?.required" class="invalid-feedback">
          Informe um nome de usuário.
        </div>

      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label for="">Senha:</label>
          <input type="password" class="form-control"
          [ngClass]="{'is-invalid': f.password.errors && f.password.touched}" formControlName="password" placeholder="Insira a senha">
          <div *ngIf="f.password.errors?.required" class="invalid-feedback">
            Senha é obrigatória.
          </div>
          <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">
            Senha deve ter 6 caracteres.
          </div>

        </div>
        <div class="form-group col-sm-6">
          <label for="">Confirmar Senha:</label>
          <input type="password" class="form-control"
          [ngClass]="{'is-invalid': f.confirmPassword.errors && f.confirmPassword.touched}" formControlName="confirmPassword" placeholder="Confirme a Senha">
          <div *ngIf="f.confirmPassword.errors?.required" class="invalid-feedback">
            Confirmação de Senha é obrigatória.
          </div>
          <div *ngIf="f.confirmPassword.errors?.mustMatch" class="invalid-feedback">
            Confirmação de Senha não confere.
          </div>

        </div>
      </div>
      <div class="form-row p-1 mb-3">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="customCheck1">
          <label class="custom-control-label">Eu concordo com os <a href="#">Termos de Uso</a>.</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <button class="btn btn-lg btn-success btn-block px-5" [disabled]="!form.valid"  (click)="cadastrarUsuario()">
            Registar
          </button>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <a class="btn btn-link btn-block" routerLink="/user/login">
            Já sou Cadastrado
          </a>
        </div>
      </div>
    </form>

	</div>
</div>
