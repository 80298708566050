// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Acesso } from '@app/models/Acesso';
import { Group } from '@app/models/Group';
import { User } from '@app/models/User';
import { AuthService } from '@app/services/auth.service';
import { AcessoService } from '@app/services/acesso.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filter, pluck } from 'rxjs/operators';


@Component({
  selector: 'app-acesso-list',
  templateUrl: './acesso-list.component.html',
  styleUrls: ['./acesso-list.component.scss'],
  // providers: [groupService]

})
export class AcessoListComponent implements OnInit {
  modalRef!: BsModalRef;
  endUser = {} as User;
  group = {} as Group;

  public acessos: Acesso[] = [];
  public acessosFiltrados: Acesso[] = [];
  public acessoId = '';
  public acessoName = '';
  public acessoEmail = '';
  public currentUser = this.authService.decodedToken?.unique_name;

  widthImg = 150;
  marginImg = 2;
  mostrarImagem = true;
  // tslint:disable-next-line:variable-name
  private _filtroLista = '';

  public get filtroLista(): string {
    return this._filtroLista;
  }

  public set filtroLista(value: string) {
    this._filtroLista = value;
    this.acessosFiltrados = this.filtroLista ? this.filtrarAcessos(this.filtroLista) : this.acessos;
  }


  public filtrarAcessos(filtrarPor: string): Acesso[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    // return this.items;
    return this.acessos.filter(
      ( acesso: { endUserAcesso: User; }) =>
        acesso.endUserAcesso.userName.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        acesso.endUserAcesso.email?.toLocaleLowerCase().indexOf(filtrarPor) !== -1
        // || acesso.group?.groupName.toLocaleLowerCase().indexOf(filtrarPor) !== -1
      );
  }


  constructor(private acessoService: AcessoService,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              public authService: AuthService
    ) { }



  public ngOnInit(): void {
    this.spinner.show();
    this.getAcesso();

    /** spinner starts on init */
  }
  public alterarImagem(): void{
    this.mostrarImagem = !this.mostrarImagem;
  }

  public getAcesso(): void {
    // tslint:disable-next-line: deprecation
    this.acessoService.getAcessos().subscribe({
        next: (acessos: Acesso[]) => {
          this.acessos = acessos;
          this.acessosFiltrados = this.acessos;
        },
        error:  (error: any) => {
          this.spinner.hide();
          this.toastr.error('Error to load Acesso', 'Error!');
        },
      complete: () => this.spinner.hide(),
      });
  }

  openModal(event: any, template: TemplateRef<any>, acessoId: string, acessoEmail: string): void {
    event.stopPropagation();
    this.acessoId = acessoId;
    this.acessoEmail = acessoEmail;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    // this.message = 'Confirmed!';

    this.modalRef.hide();
    this.spinner.show();
    this.acessoService.deleteAcesso(this.acessoId).subscribe(
      (result: any) => {

          console.log(result);
          this.toastr.success('Acesso deletado com successo.', 'Deletado!');
          this.spinner.hide();
          this.getAcesso();

      },
      (error: any) => {
        console.error(error);
        this.toastr.error(`Erro ao tentar deletar Acesso ${this.acessoName}`, 'Erro');
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      },
    );

  }

  decline(): void {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  detailAcesso(id: string): void {
    this.router.navigate([`acessos/detail/${id}`]);
  }



}
