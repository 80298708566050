<div class="card rounded shadow-sm">
  <form [formGroup]="form">
    <div class="p-3">
      <div class="form-row">
        <div class="form-check col-md-2">
          <label>Status</label><br>


          <label class="control control-checkbox">
            Ativo
            <input type="checkbox" formControlName="isActive" />
            <div class="control_indicator"></div>
          </label>
        </div>
        <div class="form-group col-md-5">
          <label>Item</label>
          <input type="text" class="form-control" formControlName="itemName" placeholder="">
        </div>
        <div class="form-group col-md-5">
          <label>Descrição</label>
          <input type="text" class="form-control" formControlName="itemDescription" placeholder="">

        </div>
      </div>
      <div class="form-row">

        <div class="form-group col-md-4">
          <label>Grupo</label>
          <select class="form-control" formControlName="groupId">
            <option *ngFor="let group of groups" value= {{group.id}}>
              {{group.groupName}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>Notificação</label>
          <select class="form-control" formControlName="notificationType">
            <option value="0">Não notificar</option>
            <option value="1">Notificar falha</option>
            <option value="2">Sempre notificar</option>

          </select>
        </div>
        <div class="form-group col-md-4">
          <label>Tempo de Repetição (Hora)</label>
          <input type="text" class="form-control" formControlName="repetitionTime" placeholder="">

        </div>
      </div>

    </div>
    <div class="card-footer">
      <div class="d-flex">
        <button class="btn btn-outline-secondary mr-auto border" (click)="resetForm()">
          Cancelar Alteração
        </button>
        <button class="btn btn-success" [disabled]="!form.valid" (click)="saveItem()">
          Salvar Grupo
        </button>
      </div>
    </div>
  </form>

</div>
