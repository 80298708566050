// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Monitor } from '@app/models/Monitor';
import { Item } from '@app/models/Item';
import { Group } from '@app/models/Group';
import { User } from '@app/models/User';

import { MonitorService } from '@app/services/monitor.service';
import { ItemDetailComponent } from '@app/components/itens/item-detail/item-detail.component';

@Component({
  selector: 'app-monitor-list',
  templateUrl: './monitor-list.component.html',
  styleUrls: ['./monitor-list.component.scss'],
  // providers: [MonitorService]

})
export class MonitorListComponent implements OnInit {
  modalRef!: BsModalRef;
  public monitors: Monitor[] = [];
  public monitorsFiltrados: Monitor[] = [];
  monitor = {} as Monitor;
  item = {} as Item;
  group = {} as Group;
  user = {} as User;
  widthImg = 150;
  marginImg = 2;
  mostrarImagem = true;
  // tslint:disable-next-line:variable-name
  private _filtroLista = '';

  public get filtroLista(): string {
    return this._filtroLista;
  }

  public set filtroLista(value: string) {
    this._filtroLista = value;
    this.monitorsFiltrados = this.filtroLista ? this.filtrarMonitors(this.filtroLista) : this.monitors;
  }

  public filtrarMonitors(filtrarPor: string): Monitor[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    // return this.Monitors;
    return this.monitors.filter(
      ( monitor: { busca: string, item: Item, user: User }) =>
        monitor.item.itemName.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        monitor.user.userName.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        monitor.item.group.groupName.toLocaleLowerCase().indexOf(filtrarPor) !== -1

      );
  }

  constructor(private monitorService: MonitorService,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router
    ) { }



  public ngOnInit(): void {
    this.spinner.show();
    this.getMonitors();
    /** spinner starts on init */
  }
  public alterarImagem(): void{
    this.mostrarImagem = !this.mostrarImagem;
  }

  public getMonitors(): void {
    // tslint:disable-next-line: deprecation
    this.monitorService.getMonitors().subscribe({
        next: (monitors: Monitor[]) => {
          this.monitors = monitors;
          this.monitorsFiltrados = this.monitors;
        },
        error:  (error: any) => {
          this.spinner.hide();
          this.toastr.error('Error to load Montiors', 'Error!');
        },
      complete: () => this.spinner.hide(),
      });
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    // this.message = 'Confirmed!';
    this.modalRef.hide();
    this.toastr.success('Monitor deleted success.', 'Deleted!');
  }

  decline(): void {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  detailMonitor(id: string): void {
    this.router.navigate([`monitors/detail/${id}`]);
  }

}
