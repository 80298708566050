
<div class="card rounded shadow-sm p-3">

  <div class="d-flex">
    <div class="flex-fill pr-3">
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <div class="input-group-text">Filter:</div>
        </div>

        <input type="text" class="form-control" placeholder="Buscar" [(ngModel)]="filtroLista">
    </div>
    </div>
    <div>
      <a class="d-flex btn btn-outline-primary" routerLink="/acessos/detail">
        <i class="fa fa-plus-circle my-1"></i>
        <b class="ml-1 d-none d-sm-block">Novo</b>
      </a>
    </div>

  </div>

  <table class="table table-striped table-hover">
    <thead class="thead-primary">
      <tr>
        <!--
        <th>
          <button type="button" class="btn btn-outline-light btn-sm"
          (click)='alterarImagem()'>
          {{!mostrarImagem ? 'Exibir' : 'Ocultar' }}
        </button>
      </th>
      -->
        <th>Status</th>
        <th>Admin</th>
        <th>Compartilhado</th>
        <th>Email</th>
        <th>Usuário</th>
        <!-- <th>Grupo</th> -->


        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="acessos && acessos.length">
      <tr *ngFor="let acesso of acessosFiltrados" (click)="detailAcesso(acesso.id)" style="cursor: pointer;">
        <td><input type="checkbox" value="{{acesso.id}}" [(ngModel)]="acesso.isActive"> Ativo</td>
        <td><input type="checkbox" value="{{acesso.id}}" [(ngModel)]="acesso.isAdmin"> Admin</td>
        <td>{{ acesso.userId === authService.decodedToken?.nameid  ? "Com" : "Por"  }}</td>

        <td>{{ acesso.userId === authService.userId()  ? acesso.endUserAcesso.email : acesso.userAcesso.email  }}</td>
        <td>{{ acesso.userId === authService.userId()  ? acesso.endUserAcesso.fullName : acesso.userAcesso.fullName  }}</td>

        <!-- <td><input type="checkbox" value="{{acesso.id}}" [(ngModel)]="acesso.IsAllGroups"> Todos</td> -->


        <td>
          <button *ngIf="acesso.userId === authService.decodedToken?.nameid" type="button" class="btn btn-danger btn-sm" tooltip='Delete' (click)="openModal($event, template, acesso.id, acesso.endUserAcesso.email    )">
            <i class="fa fa-trash "></i>
            </button>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="!acessos.length">
      <tr>
        <td colspan="8" class="text-center">
          <h4>Nenhum Acesso encontrado!</h4>
        </td>
      </tr>
    </tfoot>
  </table>

</div>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Excluir o acesso do usuário: {{this.acessoEmail}}</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Sim</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >Não</button>
  </div>
</ng-template>
