import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/services/auth.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  modalRef!: BsModalRef;
  public showSuccess = false as boolean;
  public showError = false as boolean;
  public errorMessage = '' as string;

  titulo = 'Confirmation';
  model: any = {};

  constructor(
              private authService: AuthService,
              public router: Router,
              public browserModule: BrowserModule,
              public commonModule: CommonModule,
              private toastr: ToastrService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.confirmEmail();
  }

  private confirmEmail = () => {
    this.showError = this.showSuccess = false;

    const code = this.route.snapshot.queryParams.code;
    const email = this.route.snapshot.queryParams.email;

    this.authService.confirmEmail('confirmation', email, code )
    .subscribe(_ => {
      this.showSuccess = true;
    },
    error => {
      this.showError = true;
      this.errorMessage = error;
    });
  }


}
