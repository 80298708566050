import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Acesso } from '../models/Acesso';
import { User } from '../models/User';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable(
  // { providedIn: 'root'}
  )
export class AcessoService {

  saveState = 'post' as string;
  baseURL = environment.apiURL + 'api/acessos';

  constructor(private http: HttpClient) {
   }

  public getAcessos(): Observable<Acesso[]> {

    return this.http.get<Acesso[]>(this.baseURL).pipe(take(1));
  }

  public getAcessoById(id: string): Observable<Acesso> {
    return this.http
      .get<Acesso>(`${this.baseURL}/${id}`)
      .pipe(take(1));

  }

  public post(acesso: Acesso): Observable<Acesso> {
    // console.log(this.baseURL);

    return this.http
      .post<Acesso>(this.baseURL, acesso)
      .pipe(take(1));
  }

  public put(acesso: Acesso): Observable<Acesso> {
    return this.http
      .put<Acesso>(`${this.baseURL}/${acesso.id}`, acesso)
      .pipe(take(1));

  }

  public deleteAcesso(id: string): Observable<any> {
    return this.http
      .delete(`${this.baseURL}/${id}`)
      .pipe(take(1));
  }

  public getUsers(): Observable<User[]> {

    return this.http.get<User[]>(`${this.baseURL}/Users`).pipe(take(1));
  }

}
