<nav class="navbar navbar-expand-lg navbar-dark bg-primary shadow-sm" *ngIf="showMenu()">
  <div class="container">

    <a class="navbar-brand" routerLink="dashboard">
      <img src="../../../../assets/Logo-1.png" alt="Logo" width="200px">
      <!-- A imagem abaixo exibe o logotipo da empresa

      <i class="fa fa-database text-white mr-1"></i>
      MonitorDBA
      -->
    </a>
    <button class="navbar-toggler" (click)="isCollapsed = !isCollapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="loggedIn()" class="collapse navbar-collapse" [collapse]="isCollapsed" [isAnimated]="true" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link active" aria-current="page" routerLink="monitors/list">Monitor</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="itens/list">Item</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="groups/list">Grupo</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="acessos/list">Acesso</a>
        </li>
      </ul>
      <ul *ngIf="loggedIn()" class="navbar-nav">

        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle class="nav-link dropdown-toggle" style="cursor: pointer" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{userName()}}

          </a>
          <div *dropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" (click)="detailPerfil(authService.userId())" style="cursor: pointer">
              Perfil
            </a>
            <a class="dropdown-item" (click)="detailPassword(authService.userId())" style="cursor: pointer">
              Password
            </a>
            <div role="separator" class="divider"></div>
            <a class="dropdown-item" (click)="logout()" style="cursor: pointer">
              Sair
            </a>
          </div>
        </li>
      </ul>
    </div>
    <button *ngIf="!loggedIn()" class="btn btn-success" (click)="entrar()">Entrar</button>

  </div>
</nav>
