import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Item } from '../models/Item';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable(
  // { providedIn: 'root'}
  )
export class ItemService {
  saveState = 'post' as string;
  baseURL = environment.apiURL + 'api/itens';

  constructor(private http: HttpClient) { }

  public getItens(): Observable<Item[]> {
    return this.http.get<Item[]>(this.baseURL).pipe(take(1));
  }


  public getItemById(id: string): Observable<Item> {
    return this.http
      .get<Item>(`${this.baseURL}/${id}`)
      .pipe(take(1));
  }

  public post(item: Item): Observable<Item> {
    // console.log(this.baseURL);

    return this.http
      .post<Item>(this.baseURL, item)
      .pipe(take(1));
  }

  public put(item: Item): Observable<Item> {
    return this.http
      .put<Item>(`${this.baseURL}/${item.id}`, item)
      .pipe(take(1));

  }

  public deleteItem(id: string): Observable<any> {
    return this.http
      .delete(`${this.baseURL}/${id}`)
      .pipe(take(1));
  }


}
