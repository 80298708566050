<div class="d-flex align-items-center p-2 my-2 text-white bg-primary rounded shadow-sm">
  <div class="d-flex aling-itens-center mr-auto">
    <i class="{{iconClass}} mt-1 mr-2"></i>
    <div class="lh-60">
      <h2 class="mb-0">{{title}}</h2>
      <small>{{subtitle}}</small>
    </div>
  </div>
  <div class="p-2" *ngIf="botomList">
    <button class="btn btn-outline-light" (click)="list()">Listar {{title}}</button>
  </div>
</div>
