
<div class="card rounded shadow-sm p-3">

  <div class="d-flex">
    <div class="flex-fill pr-3">
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <div class="input-group-text">Filter:</div>
        </div>

        <input type="text" class="form-control" placeholder="Buscar" [(ngModel)]="filtroLista">
    </div>
    </div>
    <div>
      <a class="d-flex btn btn-outline-primary" routerLink="/groups/detail">
        <i class="fa fa-plus-circle my-1"></i>
        <b class="ml-1 d-none d-sm-block">Novo</b>
      </a>
    </div>

  </div>

  <table class="table table-striped table-hover">
    <thead class="thead-primary">
      <tr>
        <!--
        <th>
          <button type="button" class="btn btn-outline-light btn-sm"
          (click)='alterarImagem()'>
          {{!mostrarImagem ? 'Exibir' : 'Ocultar' }}
        </button>
      </th>
      -->
        <th>Status</th>
        <th>Nome</th>
        <th>Descrição</th>


        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="groups && groups.length">
      <tr *ngFor="let group of groupsFiltrados" (click)="detailGroup(group.id)" style="cursor: pointer;">
        <td>

            <!--
            <input class="form-check-input" type="checkbox" value="{{group.isActive}}" id="flexCheckChecked" checked="">
            -->

            <input type="checkbox" value="{{group.id}}" [(ngModel)]="group.isActive"> Ativo


          <!--
          <img [collapse]="!mostrarImagem" [isAnimated]="true"
          src="/assets/{{evento.imagemURL}}"
          [style.width.px]="widthImg"
          [style.margin.px]="marginImg"
          >
          -->
        </td>
        <td>{{group.groupName}}</td>
        <td>{{group.groupDescription}}</td>


        <td>
          <button type="button" class="btn btn-danger btn-sm" tooltip='Delete' (click)="openModal($event, template, group.id, group.groupName)">
            <i class="fa fa-trash "></i>
            </button>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="!groups.length">
      <tr>
        <td colspan="8" class="text-center">
          <h4>Nenhum Group encontrado!</h4>
        </td>
      </tr>
    </tfoot>
  </table>

</div>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Deletar o grupo: {{this.groupName}}?</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Sim</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >Não</button>
  </div>
</ng-template>
