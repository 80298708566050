import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorField } from '@app/helpers/ValidatorField';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/models/User';
import { AuthService } from '@app/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  modalRef!: BsModalRef;
  user = {} as User;
  saveState = 'post' as string;
  form!: FormGroup;

  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }

  constructor(private fb: FormBuilder,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
      this.loadUser();
      this.validation();
  }
  onSumit(): void {
    if (this.form.invalid){
      return;
    }
  }

  public resetForm(): void {
    this.loadUser();
    this.validation();
  }


  public validation(): void{
    const formOptions: AbstractControlOptions = {
      validators: ValidatorField.MustMatch('password', 'confirmPassword')
    };
    this.form = this.fb.group({
      id: [''],
      fullName: ['', Validators.required],
      phoneNumber: [''],
      chatId: [''],
    });
  }

  // detailPerfil(): void {
  //  this.router.navigate([`/user/perfil`]);
  // }

  detailPerfil(id: string): void {
    // this.router.navigate([`/user/perfil/${id}`]);
    this.router.navigate([`/user/perfil`]);
  }

  public savePerfil(): void {
    this.spinner.show();

    if (this.form.valid) {
      this.user = {...this.form.value};

      if (this.saveState === 'post') {
        this.authService.post(this.user).subscribe(
            (userReturn: User) => {
              this.resetForm();
              this.toastr.success('Perfil salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`user/perfil/${userReturn.id}`]);

            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(error);
              this.toastr.error('Error ao salvar Perfil....', 'Erro');
            },
            () => this.spinner.hide()
          );
      }
    }

  }

  public loadUser(): void {
      this.spinner.show();
      const userIdParam = this.activatedRouter.snapshot.paramMap.get('id');
      if (userIdParam != null) {
        this.spinner.show();
        this.saveState = 'post';
        this.authService.getUser().subscribe(
          (user: User) => {
            this.user = {...user};
            this.form.patchValue(this.user);
          },
          (error: any) => {
            console.error(error);
            this.spinner.hide();
            this.toastr.error(error);
            this.toastr.error('Error ao carregar Perfil....', 'Erro');

          },
          () => {
            this.spinner.hide();
          },
        );
      }
    }
  }



