// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Group } from '@app/models/Group';
import { GroupService } from '@app/services/group.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
  // providers: [groupService]

})
export class GroupListComponent implements OnInit {
  modalRef!: BsModalRef;
  public groups: Group[] = [];
  public groupsFiltrados: Group[] = [];
  public groupId = '';
  public groupName = '';

  widthImg = 150;
  marginImg = 2;
  mostrarImagem = true;
  // tslint:disable-next-line:variable-name
  private _filtroLista = '';

  public get filtroLista(): string {
    return this._filtroLista;
  }

  public set filtroLista(value: string) {
    this._filtroLista = value;
    this.groupsFiltrados = this.filtroLista ? this.filtrarGroups(this.filtroLista) : this.groups;
  }

  public filtrarGroups(filtrarPor: string): Group[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    // return this.groups;
    return this.groups.filter(
      ( group: { groupName: string; groupDescription: string; }) => group.groupName.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        group.groupDescription.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  constructor(private groupService: GroupService,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router
    ) { }



  public ngOnInit(): void {
    this.spinner.show();
    this.getGroups();
    /** spinner starts on init */
  }
  public alterarImagem(): void{
    this.mostrarImagem = !this.mostrarImagem;
  }

  public getGroups(): void {
    // tslint:disable-next-line: deprecation
    this.groupService.getGroups().subscribe({
        next: (groups: Group[]) => {
          this.groups = groups;
          this.groupsFiltrados = this.groups;
        },
        error:  (error: any) => {
          this.spinner.hide();
          this.toastr.error('Error to load Groups', 'Error!');
        },
      complete: () => this.spinner.hide(),
      });
  }

  openModal(event: any, template: TemplateRef<any>, groupId: string, groupName: string): void {
    event.stopPropagation();
    this.groupId = groupId;
    this.groupName = groupName;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    // this.message = 'Confirmed!';

    this.modalRef.hide();
    this.spinner.show();
    this.groupService.deleteGroup(this.groupId).subscribe(
      (result: any) => {

          console.log(result);
          this.toastr.success('Grupo deletado com successo.', 'Deletado!');
          this.spinner.hide();
          this.getGroups();

      },
      (error: any) => {
        console.error(error);
        this.toastr.error(`Erro ao tentar deletar Group ${this.groupName}`, 'Erro');
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      },
    );

  }

  decline(): void {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  detailGroup(id: string): void {
    this.router.navigate([`groups/detail/${id}`]);
  }

}
