<div class="card rounded shadow-sm">
<form [formGroup]="form" >
    <input type="hidden" name="id" value="{{user.id}}"/>
    <div class="row p-3">
      <div class="col-lg-3 pt-1">
        <div class="card mb-3">
          <div class="card-header"></div>
          <div class="d-flex align-self-center pt-3">
            <a>
              <img
              src="../../../../assets/perfilImage.png"
              name="aboutme" width="120" height="120" class="rounded-circle">
            </a>
          </div>
          <div class="card-body">
            <div class="h4 text-center mb-3">{{user.userName}}</div>
            <div class="h7 text-bold pb-1"><b>Nome:</b> {{user.fullName}}</div>
            <div class="h7 text-bold pb-1"><b>Email:</b> {{user.email}}</div>

          </div>

          <div class="card-footer"></div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="col-lg-9">
        </div>
        <div>
          <h3 class="pt-3 border-bottom">Mudar Senha</h3>
        </div>
        <div class="form-row">
          <div class="form-group col-sm">
            <label for="">Senha:</label>
            <input type="password" class="form-control"
            [ngClass]="{'is-invalid': f.password.errors && f.password.touched}" formControlName="password" placeholder="Nova Senha">
            <div *ngIf="f.password.errors?.required" class="invalid-feedback">
              Senha é obrigatória.
            </div>
            <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">
              Senha deve ter 6 caracteres.
            </div>

          </div>
          <div class="form-group col-sm">
            <label for="">Confirmar Senha:</label>
            <input type="password" class="form-control"
            [ngClass]="{'is-invalid': f.confirmPassword.errors && f.confirmPassword.touched}" formControlName="confirmPassword" placeholder="Confirme a Senha">
            <div *ngIf="f.confirmPassword.errors?.required" class="invalid-feedback">
              Confirmação de Senha é obrigatória.
            </div>
            <div *ngIf="f.confirmPassword.errors?.mustMatch" class="invalid-feedback">
              Confirmação de Senha não confere.
            </div>

          </div>
        </div>


        <div class="form-row">
          <div class="form-group col-sm">
            <label for="">Senha Atual:</label>
            <input type="Password" class="form-control"
            [ngClass]="{'is-invalid': f.currentPassword.errors && f.currentPassword.touched}" formControlName="currentPassword" placeholder="Senha Atual">
            <div *ngIf="f.currentPassword.errors?.required" class="invalid-feedback">
              Senha é obrigatória.
            </div>
            <div *ngIf="f.currentPassword.errors?.minlength" class="invalid-feedback">
              Senha deve ter 6 caracteres.
            </div>

          </div>
          <div class="form-group col-sm">


          </div>
        </div>



      </div>

      <div class="card-footer">
        <div class="d-flex">
          <button class="btn btn-outline-secondary mr-auto border" (click)="resetForm()">
            Cancelar Alteração
          </button>
          <button class="btn btn-success" [disabled]="!form.valid" (click)="savePassword()">
            Salvar Password
          </button>
        </div>
      </div>
    </div>
</form>
</div>
