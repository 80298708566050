import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Monitor } from '@app/models/Monitor';
import { MonitorService } from '@app/services/monitor.service';
import { Item } from '@app/models/Item';
import { Group } from '@app/models/Group';
import { User } from '@app/models/User';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-monitor-detail',
  templateUrl: './monitor-detail.component.html',
  styleUrls: ['./monitor-detail.component.scss']
})
export class MonitorDetailComponent implements OnInit {
  modalRef!: BsModalRef;
  monitor = {} as Monitor;
  group = {} as Group;
  item = {} as Item;
  user = {} as User;
  saveState = 'post' as string;
  editable = false as boolean;
  form!: FormGroup;
  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }
  constructor(private fb: FormBuilder,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private monitorService: MonitorService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
    this.loadMonitor();
    this.validation();
  }

  public loadMonitor(): void {
    this.spinner.show();
    const monitorIdParam = this.activatedRouter.snapshot.paramMap.get('id');
    if (monitorIdParam != null) {
      this.saveState = 'put';
      this.monitorService.getMonitorById(monitorIdParam).subscribe(
        (monitor: Monitor) => {
          this.monitor = {...monitor};
          this.form.patchValue(this.monitor);
        },
        (error: any) => {
          console.error(error);
        },
        () => {
          this.spinner.hide();
        },
      );
    }
  }

  public validation(): void{
    this.form = this.fb.group({
      id: [],
      monitorStatus: [],
      itemId: [],
      beginDate: [],
      endDate: [],
      openMessage: [],
      closeMessage: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      activeStatus: [],
      userId: [],
      endUserId: [],
      item: [],
      user: [],
      endUser: [],
      dateUTC: [],
    });
  }

  public resetForm(): void {
    this.loadMonitor();
    this.validation();
  }

  public saveMonitor(): void {
    this.spinner.show();
    if (this.form.valid) {
      this.monitor = {...this.form.value};
      this.monitor = (this.saveState === 'post')
                ? {...this.form.value}
                : {id: this.monitor.id, ...this.form.value};
      if (this.saveState === 'post') {
        this.monitorService.post(this.monitor).subscribe(
            (monitorReturn: Monitor) => {
              this.toastr.success('Monitor salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`monitors/detail/${monitorReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(error);
              this.toastr.error('Error ao salvar Monitor....', 'Erro');
            },
            () => this.spinner.hide()
          );
      } else {
        this.monitorService.put(this.monitor).subscribe(
            (monitorReturn: Monitor) => {
              this.toastr.success('Monitor salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`monitors/detail/${monitorReturn.id}`]);
              this.ngOnInit();
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error('Error ao alterar Monitor.', 'Erro');
            },
            () => this.spinner.hide()
          );
      }
    }
  }
}
