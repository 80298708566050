
<div class="card rounded shadow-sm p-3">

  <div class="d-flex">
    <div class="flex-fill pr-3">
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <div class="input-group-text">Filtrar:</div>
        </div>

        <input type="text" class="form-control" placeholder="Buscar" [(ngModel)]="filtroLista">
    </div>
    </div>
    <!--
    <div>
      <a class="d-flex btn btn-outline-primary" routerLink="/monitors/detail">
        <i class="fa fa-plus-circle my-1"></i>
        <b class="ml-1 d-none d-sm-block">Novo</b>
      </a>
    </div>
  -->
  </div>

  <table class="table table-striped table-hover">
    <thead class="thead-primary">
      <tr>
        <!--
        <th>
          <button type="button" class="btn btn-outline-light btn-sm"
          (click)='alterarImagem()'>
          {{!mostrarImagem ? 'Exibir' : 'Ocultar' }}
        </button>
      </th>
      -->
        <th>Status</th>
        <th>Grupo</th>
        <th>Item</th>
        <th class="ml-1 d-none d-md-table-cell">Início</th>
        <th class="ml-1 d-none d-md-table-cell">Fim</th>
        <th>Usuário</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="monitors && monitors.length">
      <tr *ngFor="let monitor of monitorsFiltrados" (click)="detailMonitor(monitor.id)" style="cursor: pointer;">
        <td>
          <div class="control-group">

            <input type="checkbox" value="{{monitor.id}}" [(ngModel)]="monitor.monitorStatus"> Ativo

          </div>
          <!--
          <img [collapse]="!mostrarImagem" [isAnimated]="true"
          src="/assets/{{evento.imagemURL}}"
          [style.width.px]="widthImg"
          [style.margin.px]="marginImg"
          >
          -->
        </td>
        <!--
        <td></td>
        <td></td>
        -->
        <td>{{monitor.item.group.groupName}}</td>
        <td>{{monitor.item.itemName}}</td>

        <td class="ml-1 d-none d-md-table-cell">{{ monitor.beginDate | DateFormatPipe: '-0700'  }}</td>
        <td class="ml-1 d-none d-md-table-cell">{{ monitor.endDate | DateFormatPipe: '-0700'  }}</td>
        <td>{{monitor.user.userName}}</td>
        <!--
        <td>
          <button type="button" class="btn btn-danger btn-sm" tooltip='Delete' (click)="openModal(template)">
            <i class="fa fa-trash "></i>
            </button>
        </td>
      -->
      </tr>
    </tbody>
    <tfoot *ngIf="!monitors.length">
      <tr>
        <td colspan="8" class="text-center">
          <h4>Nenhum Monitor encontrado!</h4>
        </td>
      </tr>
    </tfoot>
  </table>

</div>


<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm Monitor Delete?</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
  </div>
</ng-template>
