import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  isCollapsed = true;
  constructor(public authService: AuthService,
              public router: Router,
              private toastr: ToastrService) { }
  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.authService.loggedIn();
  }

  loggedIn(): boolean {
    return this.authService.loggedIn();
  }

  showMenu(): boolean {
    const rotas = ['/user/login', '/user/registration', '/user/confirmation', '/user/forgot', '/user/reset'];
    // return this.router.url !== '/user/login';
    return rotas.indexOf(this.router.url) === -1;
  }

  entrar(): void {
    this.router.navigate(['/user/login']);
  }

  logout(): void {
    localStorage.removeItem('token');
    this.toastr.show('Log Out');
    this.router.navigate(['/user/login']);
  }

  userName(): any {
    const token = localStorage.getItem('token');
    if (token) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
    }
    // return sessionStorage.getItem('sub');
    // return localStorage.getItem('sub');
    return this.decodedToken?.sub;
  }

  userId(): any {
    const token = localStorage.getItem('token');
    if (token) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
    }

    // return sessionStorage.getItem('nameid');
    // return localStorage.getItem('nameid');
    return this.decodedToken?.nameid;
  }

  detailPerfil(id: string): void {
    this.router.navigate([`/user/perfil/${id}`]);
  }
  detailPassword(id: string): void {
    this.router.navigate([`/user/password/${id}`]);
  }
  detailAcesso(id: string): void {
    this.router.navigate([`/acessos/list/${id}`]);
  }
}
