import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidatorField } from '@app/helpers/ValidatorField';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/models/User';
import { AuthService } from '@app/services/auth.service';
import { ResetDto } from '@app/models/ResetDto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  public showSuccess = false as boolean;
  public showError = false as boolean;
  public errorMessage = '' as string;

  private code = '' as any;
  private email = '' as any;
  modalRef!: BsModalRef;
  user = {} as User;
  saveState = 'post' as string;
  form!: FormGroup;

  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    const newEmail = 'email';
    const newCode = 'code';
    this.route.queryParams.subscribe( parametros => {
      if (parametros[newEmail]) {
        // faça algo aqui
        this.email = parametros[newEmail];
        console.log(this.email);
      }
      if (parametros[newCode]) {
        // faça algo aqui
        this.code = parametros[newCode];
        console.log(this.code);
      }
    });
    this.validation();
    // this.code = this.route.snapshot.paramMap.get('code');
    // this.email = this.route.snapshot.paramMap.get('email');
  }

  onSumit(): void {
    if (this.form.invalid){
      return;
    }
  }
  public resetForm(): void {
    this.validation();
  }

  public validation(): void{
    const formOptions: AbstractControlOptions = {
      validators: ValidatorField.MustMatch('password', 'confirmPassword')
    };
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      // currentPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, formOptions);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  detailPassword(): void {
    this.router.navigate(['/user/reset']);
  }

  public resetPassword(formValue: any): void {

    this.showError = this.showSuccess = false;

    const resetPass = { ... formValue };
    const resetDto: ResetDto = {
      password: resetPass.password,
      confirmPassword: resetPass.confirmPassword,
      code: this.code,
      email: this.email
    };

    this.authService.resetPassword('reset', resetDto)
    .subscribe(_ => {
      this.showSuccess = true;
    },
    error => {
      this.showError = true;
      this.errorMessage = error;
    });

  }

}
