import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Monitor } from '../models/Monitor';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable(
 // { providedIn: 'root' }
)
export class MonitorService {
  baseURL = environment.apiURL + 'api/monitors';
  constructor(private http: HttpClient) { }

  getMonitors(): Observable<Monitor[]> {
    return this.http.get<Monitor[]>(this.baseURL);
  }

  getMonitorById(id: string): Observable<Monitor> {
    return this.http.get<Monitor>(`${this.baseURL}/${id}`);
  }

  public post(monitor: Monitor): Observable<Monitor> {
    return this.http
      .post<Monitor>(this.baseURL, monitor)
      .pipe(take(1));
  }

  public put(monitor: Monitor): Observable<Monitor> {
    return this.http
      .put<Monitor>(`${this.baseURL}/${monitor.id}`, monitor)
      .pipe(take(1));

  }

}
