<div class="card">
  <div class="card-body">
      <h1 class="card-title">Esqueceu a Senha</h1>

      <div *ngIf="showError" class="alert alert-danger" role="alert">
          {{errorMessage}}
      </div>

      <div *ngIf="showSuccess" class="alert alert-success" role="alert">
          {{successMessage}}
      </div>

      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="forgotPassword(form.value)">
          <div class="form-group row">
              <label for="email" class="col-form-label col-sm-2">Email:</label>
              <div class="col-md-5">
                  <input type="text" id="email" formControlName="email" class="form-control" />
              </div>
              <div class="col-md-5">
                  <em *ngIf="validateControl('email') && hasError('email', 'required')">Email is required</em>
              </div>
          </div>
          <br>
          <div class="form-group row">
              <div class="col-md-1">
                  <button type="submit" class="btn btn-info" [disabled]="!form.valid">Submit</button>
              </div>
          </div>
      </form>
  </div>
</div>
