import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Item } from '@app/models/Item';
import { Group } from '@app/models/Group';
import { ItemService } from '@app/services/item.service';
import { GroupService } from '@app/services/group.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class ItemDetailComponent implements OnInit {

  modalRef!: BsModalRef;
  item = {} as Item;
  // group = {} as Group;
  groups = {} as Group[];
  saveState = 'post' as string;
  form!: FormGroup;

  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }
  constructor(private fb: FormBuilder,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private itemService: ItemService,
              private groupService: GroupService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
    this.loadItem();
    this.getGroups();
    this.validation();
  }

  public loadItem(): void {
    const itemIdParam = this.activatedRouter.snapshot.paramMap.get('id');
    if (itemIdParam != null) {
      this.spinner.show();
      this.saveState = 'put';
      this.itemService.getItemById(itemIdParam).subscribe(
        (item: Item) => {
          this.item = {...item};
          this.form.patchValue(this.item);
        },
        (error: any) => {
          console.error(error);
        },
        () => {
          this.spinner.hide();
        },
      );
    }

  }

  public validation(): void{
    this.form = this.fb.group({
      id: [],
      isActive: [],
      itemName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      itemDescription: [],
      notificationType: [],
      repetitionTime: [],
      groupId: [],
      group: [],
    });
  }

  public resetForm(): void {
    // this.form.reset();
    this.loadItem();
    this.getGroups();
    this.validation();
  }

  public saveItem(): void {
    this.spinner.show();

    if (this.form.valid) {
      this.item = {...this.form.value};

      // this.group = {...this.form.value};

      this.item = (this.saveState === 'post')
                ? {...this.form.value}
                : {id: this.item.id, ...this.form.value};
      if (this.saveState === 'post') {
        // this.groupService.post(this.group).subscribe(
        this.itemService.post(this.item).subscribe(
            (itemReturn: Item) => {
              this.toastr.success('Item salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`itens/detail/${itemReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(error);
              this.toastr.error('Error ao salvar Item....', 'Erro');
            },
            () => this.spinner.hide()
          );
      } else {
        this.itemService.put(this.item).subscribe(
          // this.groupService.post(this.group).subscribe(
            (itemReturn: Item) => {
              this.toastr.success('Item salvo com Sucesso!', 'Sucesso');
              this.router.navigate([`itens/detail/${itemReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error('Error ao alterar Item.', 'Erro');
            },
            () => this.spinner.hide()
          );

      }
    }

  }

  public getGroups(): void {
    // tslint:disable-next-line: deprecation
    this.groupService.getGroups().subscribe({
        next: (groups: Group[]) => {
          this.groups = groups;
        },
        error:  (error: any) => {
          this.spinner.hide();
          this.toastr.error('Error to load Group', 'Error!');
        },
      complete: () => this.spinner.hide(),
      });
  }

}
