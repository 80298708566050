// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef } from '@angular/core';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';
// import { Monitor } from '@app/models/Monitor';
// import { MonitorService } from '@app/services/monitor.service';

@Component({
  selector: 'app-monitors',
  templateUrl: './monitors.component.html',
  styleUrls: ['./monitors.component.scss'],
  // providers: [MonitorService]

})
export class MonitorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void{

  }
}
