<div class="card">

    <div class="card-body">
      <h1 class="card-title">Confirmar Email:</h1>

      <div *ngIf="showSuccess" class="alert alert-success" role="alert">
        Seu email confirmado com sucesso. Acesse página de login.
        <a class="btn btn-lg btn-link btn-block" routerLink="/user/login">Login</a>
      </div>

      <div *ngIf="showError" class="alert alert-danger" role="alert">
        Seu email não foi confirmado:
        {{errorMessage}}
      </div>

    </div>


</div>
