import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group } from '../models/Group';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable(
  // { providedIn: 'root'}
  )
export class GroupService {

  saveState = 'post' as string;
  baseURL = environment.apiURL + 'api/groups';

  constructor(private http: HttpClient) {
   }

  public getGroups(): Observable<Group[]> {

    return this.http.get<Group[]>(this.baseURL).pipe(take(1));
  }

  public getGroupById(id: string): Observable<Group> {
    return this.http
      .get<Group>(`${this.baseURL}/${id}`)
      .pipe(take(1));

  }

  public post(group: Group): Observable<Group> {
    // console.log(this.baseURL);

    return this.http
      .post<Group>(this.baseURL, group)
      .pipe(take(1));
  }

  public put(group: Group): Observable<Group> {
    return this.http
      .put<Group>(`${this.baseURL}/${group.id}`, group)
      .pipe(take(1));

  }

  public deleteGroup(id: string): Observable<any> {
    return this.http
      .delete(`${this.baseURL}/${id}`)
      .pipe(take(1));
  }

}
