
<div class="card rounded shadow-sm">
<form [formGroup]="form" >
  <input type="hidden" name="id" value="{{user.id}}"/>
	<div class="row p-3">
		<div class="col-lg-3 pt-1">
			<div class="card mb-3">
				<div class="card-header"></div>
				<div class="d-flex align-self-center pt-3">
					<a>
						<img
						src="../../../../assets/perfilImage.png"
						name="aboutme" width="120" height="120" class="rounded-circle">
					</a>
				</div>
				<div class="card-body">
					<div class="h4 text-center mb-3">{{user.userName}}</div>

					<div class="h7 text-bold pb-1"><b>Nome:</b> {{user.fullName}}</div>
          <div class="h7 text-bold pb-1"><b>Email:</b> {{user.email}}</div>


				</div>

				<div class="card-footer"></div>
			</div>
		</div>
		<div class="col-lg-9">
			<div class="form-row">
				<div class="form-group col-12">
					<h3 class="border-bottom">Detalhe Perfil</h3>
				</div>

				<div class="form-group col-sm-6">

          <label>Nome</label>
          <input type="text" class="form-control"
          [ngClass]="{'is-invalid': f.fullName.errors && f.fullName.touched}" formControlName="fullName" placeholder="Insira o nome">
          <div *ngIf="f.fullName.errors?.required" class="invalid-feedback">
            O nome é obrigatório.
          </div>
				</div>
				<div class="form-group col-sm-6">
          <label>Telefone</label>
          <input type="text" class="form-control" formControlName="phoneNumber" placeholder="Informe o Celular">

				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-sm-6">
          <label>ChatId Telegram</label>
          <input type="text" class="form-control" formControlName="chatId" placeholder="ChatId Telegram para receber Notificação">
				</div>
		  </div>

		</div>
    <div class="card-footer">
      <div class="d-flex">
        <button class="btn btn-outline-secondary mr-auto border" (click)="resetForm()">
          Cancelar Alteração
        </button>
        <button class="btn btn-success" [disabled]="!form.valid" (click)="savePerfil()">
          Salvar Perfil
        </button>
      </div>
    </div>
	</div>

</form>
</div>
