// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Item } from '@app/models/Item';
import { Group } from '@app/models/Group';
import { ItemService } from '@app/services/item.service';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit {
  modalRef!: BsModalRef;
  group = {} as Group;
  public itens: Item[] = [];
  public itensFiltrados: Item[] = [];
  public itemId = '';
  public itemName = '';
  widthImg = 150;
  marginImg = 2;
  mostrarImagem = true;
  // tslint:disable-next-line:variable-name
  private _filtroLista = '';

  public get filtroLista(): string {
    return this._filtroLista;
  }

  public set filtroLista(value: string) {
    this._filtroLista = value;
    this.itensFiltrados = this.filtroLista ? this.filtrarItens(this.filtroLista) : this.itens;
  }

  public filtrarItens(filtrarPor: string): Item[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    // return this.items;
    return this.itens.filter(
      ( item: { itemName: string; itemDescription: string; group: Group }) =>
        item.itemName.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        item.itemDescription.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        item.group.groupName.toLocaleLowerCase().indexOf(filtrarPor) !== -1
      );
  }

  constructor(private itemService: ItemService,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router
    ) { }

  public ngOnInit(): void {
    this.spinner.show();
    this.getItens();
    /** spinner starts on init */
  }
  public alterarImagem(): void{
    this.mostrarImagem = !this.mostrarImagem;
  }

  public getItens(): void {
    // tslint:disable-next-line: deprecation
    this.itemService.getItens().subscribe({
        next: (itens: Item[]) => {
          this.itens = itens;
          this.itensFiltrados = this.itens;
        },
        error:  (error: any) => {
          this.spinner.hide();
          this.toastr.error('Error to load Montiors', 'Error!');
        },
      complete: () => this.spinner.hide(),
      });
  }


  openModal(event: any, template: TemplateRef<any>, itemId: string, itemName: string): void {
    event.stopPropagation();
    this.itemId = itemId;
    this.itemName = itemName;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    // this.message = 'Confirmed!';
    this.modalRef.hide();
    this.spinner.show();
    this.itemService.deleteItem(this.itemId).subscribe(
      (result: any) => {
          console.log(result);
          this.toastr.success('Item deletado com successo.', 'Deletado!');
          this.spinner.hide();
          this.getItens();
      },
      (error: any) => {
        console.error(error);
        this.toastr.error(`Erro ao tentar deletar Item ${this.itemName}`, 'Erro');
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      },
    );

  }


  decline(): void {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  detailItem(id: string): void {
    this.router.navigate([`itens/detail/${id}`]);
  }

}
