import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Acesso } from '@app/models/Acesso';
import { User } from '@app/models/User';
import { Group } from '@app/models/Group';
import { AcessoService } from '@app/services/acesso.service';
import { GroupService } from '@app/services/group.service';
import { AuthService } from '@app/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-acesso-detail',
  templateUrl: './acesso-detail.component.html',
  styleUrls: ['./acesso-detail.component.scss']
})
export class AcessoDetailComponent implements OnInit {
  modalRef!: BsModalRef;
  acesso = {} as Acesso;
  endUser = {} as User;
  user = {} as User;
  group = {} as Group;
  groups = {} as Group[];

  saveState = 'post' as string;
  disabled = true;
  form!: FormGroup;

  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }
  constructor(private fb: FormBuilder,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private acessoService: AcessoService,
              private groupService: GroupService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public authService: AuthService
              ) { }

  ngOnInit(): void {
    this.loadAcesso();
    this.getGroups();
    this.validation();

  }


  public loadAcesso(): void {
    const acessoIdParam = this.activatedRouter.snapshot.paramMap.get('id');
    if (acessoIdParam != null) {
      this.spinner.show();
      this.saveState = 'put';
      this.acessoService.getAcessoById(acessoIdParam).subscribe(
        (acesso: Acesso) => {
          this.acesso = {...acesso};
          this.form.patchValue(this.acesso);
        },
        (error: any) => {
          console.error(error);
        },
        () => {
          this.spinner.hide();
        },
      );
    }

  }

  public validation(): void{

    this.form = this.fb.group({
      id: [],
      isActive: [],
      isAdmin: [],
      email: [],
      userId: [],
      user: [],
      endUserId: ['', [Validators.required]],
      endUser: [],

    });

  }

  public resetForm(): void {
    this.loadAcesso();
    this.validation();
  }


openModal(event: any, template: TemplateRef<any>): void {
  event.stopPropagation();
  this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
}



public getGroups(): void {
  // tslint:disable-next-line: deprecation
  this.groupService.getGroups().subscribe({
      next: (groups: Group[]) => {
        this.groups = groups;
      },
      error:  (error: any) => {
        this.spinner.hide();
        this.toastr.error('Error to load Group', 'Error!');
      },
    complete: () => this.spinner.hide(),
    });
}

public getUser(email: string): void {

  // tslint:disable-next-line: deprecation
  this.authService.getUserByEmail(email).subscribe({
      next: (user: User) => {
        // this.endUser = user;
        if (user !== null) {
          this.form.controls.endUserId.setValue(user.id);
          this.form.controls.endUser.setValue(user);
        } else {
          this.spinner.hide();
          this.toastr.error('Usuário não encontrado!', 'Error!');
        }
      },
      error:  (error: any) => {
        this.spinner.hide();
        this.toastr.error('Erro ao buscar Usuário!', 'Error!');
      },
    complete: () => this.spinner.hide(),
    });
}


decline(): void {
  // this.message = 'Declined!';
  this.modalRef.hide();
}

  confirm(): void {
    // this.message = 'Confirmed!';
    this.modalRef.hide();
    this.spinner.show();
    this.getUser(this.form.controls.email.value);

    // this.toastr.success('Usuário localizado com sucesso.', 'Atualizado!');
    this.spinner.hide();
  }

  public saveAcesso(): void {
    this.spinner.show();
    if (this.form.valid) {
      this.acesso = {...this.form.value};


      this.acesso = (this.saveState === 'post')
                ? {...this.form.value}
                : {id: this.acesso.id, ...this.form.value};
      if (this.saveState === 'post') {

        // this.groupService.post(this.group).subscribe(
        this.acessoService.post(this.acesso).subscribe(
            (acessoReturn: Acesso) => {
              this.toastr.success('Acesso salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`acessos/detail/${acessoReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(error);
              this.toastr.error('Error ao salvar acesso....', 'Erro');
            },
            () => this.spinner.hide()
          );
      } else {
        this.acessoService.put(this.acesso).subscribe(
            (acessoReturn: Acesso) => {
              this.toastr.success('Acesso salvo com Sucesso!', 'Sucesso');
              this.router.navigate([`acessos/detail/${acessoReturn.id}`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error('Error ao salvar acesso', 'Erro');
            },
            () => this.spinner.hide()
          );

      }
    }

  }
}
