<div class="card rounded shadow-sm">
  <form [formGroup]="form">
    <input type="hidden" name="activeStatus" value="{{monitor.activeStatus}}"/>
    <input type="hidden" name="userId" value="{{monitor.userId}}"/>
    <div class="p-3">
      <div class="form-row">

        <div class="form-check col-md-2">
          <label><b>Status</b></label>

          <label class="control control-checkbox">
            Ativo
            <input type="checkbox" formControlName="monitorStatus" />
            <div class="control_indicator"></div>
          </label>
        </div>

        <div class="form-group col-md-4">
          <label><b>Grupo</b></label>
          <!--
          <td class="ml-1 d-none d-md-table-cell">
                        <div *ngIf="monitor.itemId;else other_content">
            -->

            <p>{{monitor.item.group.groupName}}</p>

<!--
              <ng-template #other_content>*</ng-template>
            </div>
            </div>

          </div>

        </td> -->
        </div>


        <div class="form-group col-md-4">
          <label><b>Item</b></label>
          <!--
            <div class="d-flex align-items-center">
          <td class="ml-1 d-none d-md-table-cell">
                        <div *ngIf="monitor.itemId;else other_content">
            -->
            <p>{{monitor.item.itemName}}</p>
        <!--
                      <ng-template #other_content>*</ng-template>
          </div>
          </div>
          </td>
        -->
        </div>


      </div>

      <div class="form-row">
        <div class="form-group col-md-2">
          <label><b>Data Inicial</b></label>
          <!-- <td class="ml-1 d-none d-md-table-cell">-->
            <p>{{monitor.beginDate| DateFormatPipe: '-0700'}}</p>
          <!--</td>-->
        </div>

        <div class="form-group col-md-4">
          <label><b>Usuário Inicial</b></label>
          <!-- <td class="ml-1 d-none d-md-table-cell">
            <div *ngIf="monitor.beginDate;else other_content"> -->
            <p>{{monitor.user.userName}}</p>
            <!--
          </div>
          <ng-template #other_content>*</ng-template>
           </td>-->
          <!-- <td class="ml-1 d-none d-md-table-cell">{{monitor.user.userName}}</td>
            -->
          </div>

        <div class="form-group col-md-2">
          <label><b>Data Final</b></label>
          <!-- <td class="ml-1 d-none d-md-table-cell"> -->
            <div *ngIf="monitor.endDate;else other_content">
            <p>{{monitor.endDate | DateFormatPipe: '-0700'}}</p>
            </div>
          <ng-template #other_content><p>-</p></ng-template>

          <!--</td> -->
        </div>

        <div class="form-group col-md-4">
          <label><b>Usuário Final</b></label>
          <!--  <td class="ml-1 d-none d-md-table-cell"> -->
            <div *ngIf="monitor.endDate;else other_content">
            <p>{{monitor.endUser.userName}}</p>
          </div>
          <ng-template #other_content><p>*</p></ng-template>
          <!--  </td>-->
        </div>

      </div>


      <div class="form-row">
        <div class="form-group col-md-4">
          <label><b>Mensagem</b></label>
          <!--  <td class="ml-1 d-none d-md-table-cell"> -->
            <p>{{monitor.openMessage}}</p>
          <!--</td>-->
        </div>

      <!--
        <div class="form-group col-md-12">
          <label>Observação</label>
          <input type="text" class="form-control" formControlName="openMessage" placeholder="" readonly>
        </div>
      -->
      </div>


      <div class="form-row">
        <div class="form-group col-md-12">
          <label><b>Conclusão</b></label>
          <input type="text" class="form-control"
          [ngClass]="{'is-invalid': f.closeMessage?.errors && f.closeMessage?.touched}" formControlName="closeMessage" placeholder="">
          <div *ngIf="f.closeMessage.errors?.required"class="invalid-feedback">
            Conclusão é obrigatória
          </div>
          <div *ngIf="f.closeMessage.errors?.minlength" class="invalid-feedback">
            Conclusão deve ter no mínimo 10.
          </div>
          <div *ngIf="f.closeMessage.errors?.maxlength" class="invalid-feedback">
            Conclusão deve ter no máximo 255.
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex">
        <button class="btn btn-outline-secondary mr-auto border" (click)="resetForm()">
          Cancelar Alteração
        </button>
        <button class="btn btn-success" [disabled]="!form.valid" (click)="saveMonitor()">
          Salvar Monitor
        </button>
      </div>
    </div>

  </form>

</div>
