import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() iconClass!: string;
  @Input() botomList = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  list(): void {
    this.router.navigate([`/${this.title.toLocaleLowerCase()}/list`]);
  }
}
