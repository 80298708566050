import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { User } from '../models/User';
import { Forgot } from '@app/models/Forgot';
import { ResetDto } from '@app/models/ResetDto';
import { map, take } from 'rxjs/operators';
import { CustomEncoder } from '../custom-encoder';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private currentUserSource = new ReplaySubject<User | null>(1);
  public currentUser$ = this.currentUserSource.asObservable();


  baseURL = environment.apiURL + 'api/user';
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) { }


  public getUser(): Observable<User> {
    return this.http
      .get<User>(`${this.baseURL}`);
  }

  public getUserById(id: string): Observable<User> {
    return this.http
      .get<User>(`${this.baseURL}/${id}`)
      .pipe(take(1));
  }

  public getUserByEmail(email: string): Observable<User> {
    return this.http
      .get<User>(`${this.baseURL}/GetUser/${email}`)
      .pipe(take(1));
  }

  public post(user: User): Observable<User> {
    // console.log(this.baseURL);

    return this.http
      .post<User>(this.baseURL, user);
  }

  // tslint:disable-next-line:typedef
  login(model: any) {
    return this.http
   .post(`${this.baseURL}/login`, model).pipe(
    map((response: any ) => {
      const user = response;
      if (user) {
        localStorage.setItem('token', user.token);
        this.decodedToken = this.jwtHelper.decodeToken(user.token);
      }
    })
   );
  }


  // tslint:disable-next-line:typedef
  register(model: any) {
    return this.http.post(`${this.baseURL}/register`, model);
  }

  // tslint:disable-next-line:typedef
  loggedIn() {
      let token: any = localStorage.getItem('token');

      if (token == null) {
        token = undefined;
      }
      return !this.jwtHelper.isTokenExpired(token);

      // return token !== null && !this.jwtHelper.isTokenExpired(token);

  }

  logout(): void {
    localStorage.removeItem('user');
    this.currentUserSource.next(null);
    this.currentUserSource.complete();
  }

  public userName(): any {
    const token = localStorage.getItem('token');
    if (token) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
    }
    // return sessionStorage.getItem('sub');
    // return localStorage.getItem('sub');
    return this.decodedToken?.sub;
  }

  public userId(): any {
    const token = localStorage.getItem('token');
    if (token) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
    }

    // return sessionStorage.getItem('nameid');
    // return localStorage.getItem('nameid');
    return this.decodedToken?.nameid;
  }

  public setCurrentUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSource.next(user);
  }

  public confirmEmail = (route: string, email: string, code: string ) => {
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('email', email);
    params = params.append('code', code);


    return this.http.get(this.createCompleteRoute(route, `${this.baseURL}`), { params });
  }

  public forgotPassword = (route: string, body: Forgot) => {
    return this.http.post(this.createCompleteRoute(route, `${this.baseURL}`), body);
  }

  public resetPassword = (route: string, body: ResetDto) => {
    return this.http.post(this.createCompleteRoute(route, `${this.baseURL}`), body);
  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

}
