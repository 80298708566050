import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MonitorsComponent } from './components/monitors/monitors.component';
import { ItensComponent } from './components/itens/itens.component';
import { GroupsComponent } from './components/groups/groups.component';
import { AcessosComponent } from './components/acessos/acessos.component';
import { PerfilComponent } from './components/user/perfil/perfil.component';
import { PasswordComponent } from './components/user/password/password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MonitorDetailComponent } from './components/monitors/monitor-detail/monitor-detail.component';
import { MonitorListComponent } from './components/monitors/monitor-list/monitor-list.component';
import { LoginComponent } from './components/user/login/login.component';
import { ConfirmationComponent } from './components/user/confirmation/confirmation.component';
import { ForgotComponent } from './components/user/forgot/forgot.component';
import { ResetComponent } from './components/user/reset/reset.component';
import { RegistrationComponent } from './components/user/registration/registration.component';
import { UserComponent } from './components/user/user.component';
import { ItemDetailComponent } from './components/itens/item-detail/item-detail.component';
import { GroupDetailComponent } from './components/groups/group-detail/group-detail.component';
import { GroupListComponent } from './components/groups/group-list/group-list.component';
import { AcessoDetailComponent } from './components/acessos/acesso-detail/acesso-detail.component';
import { AcessoListComponent } from './components/acessos/acesso-list/acesso-list.component';

import { ItemListComponent } from './components/itens/item-list/item-list.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] ,
    children: [
      { path: 'perfil', component: PerfilComponent },
      { path: 'perfil/:id', component: PerfilComponent },
      { path: 'password', component: PasswordComponent },
      { path: 'password/:id', component: PasswordComponent },
    ]
  },
  { path: 'user/login', component: LoginComponent },
  { path: 'user/registration', component: RegistrationComponent },
  { path: 'user/confirmation', component: ConfirmationComponent },
  { path: 'user/forgot', component: ForgotComponent },
  { path: 'user/reset', component: ResetComponent },
  { path: 'monitors', redirectTo: 'monitors/list' },
  { path: 'monitors', component: MonitorsComponent, canActivate: [AuthGuard] ,
    children: [
     { path: 'detail/:id', component: MonitorDetailComponent },
     { path: 'detail', component: MonitorDetailComponent },
     { path: 'list', component: MonitorListComponent },
    ]
  },
  { path: 'itens', redirectTo: 'itens/list' },
  { path: 'itens', component: ItensComponent, canActivate: [AuthGuard] ,
    children: [
     { path: 'detail/:id', component: ItemDetailComponent },
     { path: 'detail', component: ItemDetailComponent },
     { path: 'list', component: ItemListComponent },
    ]
  },
  { path: 'groups', redirectTo: 'groups/list' },
  { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard] ,
    children: [
     { path: 'detail/:id', component: GroupDetailComponent },
     { path: 'detail', component: GroupDetailComponent },
     { path: 'list', component: GroupListComponent },
    ]
  },
  { path: 'acessos', redirectTo: 'acessos/list' },
  { path: 'acessos', component: AcessosComponent, canActivate: [AuthGuard] ,
    children: [
     { path: 'detail/:id', component: AcessoDetailComponent },
     { path: 'detail', component: AcessoDetailComponent },
     { path: 'list', component: AcessoListComponent },
    ]
  },
  // { path: 'itens', component: ItensComponent },
  // { path: 'groups', component: GroupsComponent },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
