import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acessos',
  templateUrl: './acessos.component.html',
  styleUrls: ['./acessos.component.scss']
})
export class AcessosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
