import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidatorField } from '@app/helpers/ValidatorField';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/models/User';
import { AuthService } from '@app/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  modalRef!: BsModalRef;
  user = {} as User;
  saveState = 'post' as string;
  form!: FormGroup;

  get modoEditar(): boolean {
    return this.saveState === 'put';
  }

  get f(): any{
    return this.form.controls;
  }

  constructor(private fb: FormBuilder,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this.loadUser();
    this.validation();
  }

  onSumit(): void {
    if (this.form.invalid){
      return;
    }
  }
  public resetForm(): void {
    this.loadUser();
    this.validation();
  }

  public validation(): void{
    const formOptions: AbstractControlOptions = {
      validators: ValidatorField.MustMatch('password', 'confirmPassword')
    };
    this.form = this.fb.group({
      id: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      currentPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, formOptions);
  }

  detailPassword(): void {
    this.router.navigate([`/user/password`]);
  }

  public savePassword(): void {

    this.spinner.show();
    this.user = {...this.form.value};

    if (this.form.valid) {
      this.user = {...this.form.value};

      if (this.saveState === 'post') {
        this.authService.post(this.user).subscribe(
            (userReturn: User) => {
              this.resetForm();
              this.toastr.success('Password salvo com Sucesso!...', 'Sucesso');
              this.router.navigate([`user/password/${userReturn.id}`]);
              // this.router.navigate([`user/password`]);
            },
            (error: any) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(error);
              this.toastr.error('Error ao salvar password....', 'Erro');
            },
            () => this.spinner.hide()
          );
      }
    }

  }

  public loadUser(): void {

    this.spinner.show();
    const userIdParam = this.activatedRouter.snapshot.paramMap.get('id');
    if (userIdParam != null) {

      this.saveState = 'post';
      this.authService.getUser().subscribe(
        (user: User) => {
          this.user = {...user};
          this.user.password = '';
          this.form.patchValue(this.user);
        },
        (error: any) => {
          console.error(error);
          this.spinner.hide();
          this.toastr.error(error);
          this.toastr.error('Error ao carregar usuário....', 'Erro');
        },
        () =>  {
          this.spinner.hide();
        },
      );
    }
  }
}
